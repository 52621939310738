import React from "react";
import { Link } from "gatsby";
import "./WarmupListItem.css";

const WarmupListItem = ({ warmup }) => {
  return (
    <Link to={`/warmups/${warmup.slug}`} style={{ color: "var(--black)", textDecoration: "none" }}>
      <div className="WarmupListItem-container">
        <div className="WarmupListItem-header">{warmup.title}</div>
        <div>{warmup.description}</div>
      </div>
    </Link>
  )
}

export default WarmupListItem
