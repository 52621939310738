import React from "react"
import { Link } from "gatsby"
import { Location } from '@reach/router';

import Layout from "../components/layout"

import SEO from "../components/seo"
import WarmupList from '../components/warmups/WarmupList'
import WeirdPics from '../components/splash/WeirdPics';

const ListPage = () => (
  <Location>
    {
      locationProps =>
        <Layout subtleButton>
          <div style={{ background: "var(--light-bg)", width: "100%", padding: 20, paddingBottom: 120 }}>
            <SEO title="All Warmups" />
            <WarmupList locationProps={locationProps} />
          </div>
          <WeirdPics />
        </Layout>
      }
    </Location>
)

export default ListPage
