import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./GroupSizeSelector.css";
import { GROUP_SIZES } from './groupSizes';

const GroupSizeSelector = ({ groupSize, updateGroupSize }) => {
  return (
    <div className="GroupSizeSelector-container">
      <p className="GroupSizeSelector-header">Who are you<br/> meeting with?</p>
      <form className="GroupSizeSelector-options-container">
        <label className={groupSize === GROUP_SIZES.PAIR ? 'active' : null}>
          <div className="GroupSizeSelector-emphasis">Pair</div>
          <div className="GroupSizeSelector-sub">2 people</div>
          <input type="radio" name="group_size" onChange={() => updateGroupSize(GROUP_SIZES.PAIR)} checked={groupSize === GROUP_SIZES.PAIR} value={GROUP_SIZES.PAIR} />
        </label>
        <label className={groupSize === GROUP_SIZES.POD ? 'active' : null}>
          <div className="GroupSizeSelector-emphasis">Pod</div>
          <div className="GroupSizeSelector-sub">3-6 people</div>
          <input type="radio" name="group_size" onChange={() => updateGroupSize(GROUP_SIZES.POD)} checked={groupSize === GROUP_SIZES.POD} value={GROUP_SIZES.POD} />
        </label>
        <label className={groupSize === GROUP_SIZES.SQUAD ? 'active' : null}>
          <div className="GroupSizeSelector-emphasis">Squad</div>
          <div className="GroupSizeSelector-sub">7+ people</div>
          <input type="radio" name="group_size" onChange={() => updateGroupSize(GROUP_SIZES.SQUAD)} checked={groupSize === GROUP_SIZES.SQUAD} value={GROUP_SIZES.SQUAD} />
        </label>
      </form>
      <div className="GroupSizeSelector-arrow-contain">
        <FontAwesomeIcon icon="arrow-down" />
      </div>
    </div>
  )
}

export default GroupSizeSelector
