import React from "react";
import { Link } from "gatsby"
import "./WarmupCard.css";

const WarmupCard = ({ warmup }) => {
  return (
    <Link
      className="WarmupCard-link"
      to={`warmups/${warmup.slug}`}
      state={{ fromFeed: "hi" }}
    >
      <div className="WarmupCard-container">
        <div className="WarmupCard-title">{warmup.title}</div>
        <p>{warmup.description}</p>
      </div>
    </Link>
  )
}

export default WarmupCard
