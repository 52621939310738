import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { GROUP_SIZES } from './groupSizes';
import './WarmupIndexHeader.css';

const WarmupIndexHeader = ({ groupSize }) => {

  const query = useStaticQuery(graphql`
    query {
      pair: file(relativePath: { eq: "warmup-pair.png" }) {
        ...headerImage
      }
      pod: file(relativePath: { eq: "warmup-pod.png" }) {
        ...headerImage
      }
      squad: file(relativePath: { eq: "warmup-squad.png" }) {
        ...headerImage
      }
    }
  `);

  return (
    <div className="WarmupIndexHeader-container">
      <div className="WarmupIndexHeader-picture">
        {getImage(groupSize, query)}
      </div>
    </div>
  );
}

const getImage = (groupSize, query) => {
  switch(groupSize) {
    case GROUP_SIZES.PAIR: {
      return <Img fluid={query.pair.childImageSharp.fluid} />
    }
    case GROUP_SIZES.POD: {
      return <Img fluid={query.pod.childImageSharp.fluid} />
    }
    case GROUP_SIZES.SQUAD: {
      return <Img fluid={query.squad.childImageSharp.fluid} />
    }
    default: {
      return null;
    }
  }
}
export const headerImage = graphql`
  fragment headerImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, maxHeight: 615) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default WarmupIndexHeader
