/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import queryString from 'query-string';
import GroupSizeSelector from './GroupSizeSelector';
import WarmupCard from './WarmupCard';
import WarmupListItem from './WarmupListItem';
import Image from '../image';

import WarmupIndexHeader from './WarmupIndexHeader';
import RandomWarmupSelector from './RandomWarmupSelector';
import './WarmupList.css';

const WarmupList = ({ children, locationProps }) => {

  const parsed = queryString.parse(locationProps.location.search);

  const [groupSize, setGroupSize] = useState(parsed.size || "pod");
  const data = useStaticQuery(graphql`
    query WarmupListQuery {
      swapi {
        warmups {
          id
          title
          slug
          description
          groupSizes
        }
      }
    }
  `);

  const filterWarmups = (warmups, groupSize) => {
    return warmups.filter((warmup) => {
      return (
        !warmup.groupSizes ||
        warmup.groupSizes.length === 0 ||
        warmup.groupSizes.filter((size => size === groupSize)).length > 0
      )
    })
  }

  let warmups = filterWarmups(data.swapi.warmups, groupSize);
  return (
    <div className="WarmupList-container">
      {
        warmups.map((warmup, index) => {
          return (
            <WarmupListItem key={index} warmup={warmup} />
          );
        })
      }
    </div>
  )
}

export default WarmupList
